import { gql } from "@apollo/client";

export const _createStudentOTpSend = gql`
  mutation CreateStudentOTpSend($input: createStudentOTpSendIn) {
    createStudentOTpSend(input: $input) {
      status
    }
  }
`;
export const _createStudentOTpVerify = gql`
  mutation CreateStudentOTpVerify($input: createStudentOTpVerifyIn) {
    createStudentOTpVerify(input: $input) {
      mobileVerifedToken
    }
  }
`;
export const _createStudent = gql`
  mutation CreateStudent($input: UserAccountIn) {
    createStudent(input: $input) {
      uploadToken
    }
  }
`;

export const _completeStudentCreation = gql`
  mutation CompleteCreateStudent($input: CompleteUserAccountIn) {
    completCreateStudent(input: $input) {
      accessToken
      userAccount {
        _id
        fname
        lname
        email
        role
        status
        created_at
        updated_at
      }
    }
  }
`;

export const _loginStudent = gql`
  mutation loginStudent($input: UserLogIn) {
    loginStudent(input: $input) {
      accessToken
      userAccount {
        _id
        fname
        lname
        email
        role
        status
        created_at
        updated_at
      }
    }
  }
`;

export const _me = gql`
  query Me {
    me {
      _id
      fname
      lname
      email
      role
      status
      created_at
      updated_at
    }
  }
`;
export const _getStudentMyProfile = gql`
  query MyProfile {
    getStudentMyProfile {
      account {
        _id
        fname
        lname
        email
        status
        role
        created_at
        updated_at
      }
      profile {
        _id
        account_id
        ewings_id
        full_name
        school_name
        subject_straem
        subjects
        exm_attempt
        exm_year
        closet_town
        district
        address
        nic_no
        selfy_photo1
        nic_front_url
        nic_back_url
        selfy_photo_new
        nic_front_url_new
        nic_back_url_new
        nic_status
        avatar_url
        avatar_url_new
        email
        email_verified_at
        barcode
        mobile
        mobile_verified_at
        del_mobile_1
        del_mobile_2
        status
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

export const _updateStudentProfile = gql`
  mutation UpdateStudentProfile($input: StudentProfileIn) {
    updateStudentProfile(input: $input) {
      _id
      update_status
      updated_at
    }
  }
`;

export const _forgetPasswordOtpSend = gql`
  mutation ChangePasswordReq($input: changePasswordReqIn) {
    changePasswordReq(input: $input) {
      status
    }
  }
`;
export const _forgetPassword = gql`
  mutation ChangePassword($input: changePasswordIn) {
    changePassword(input: $input) {
      status
    }
  }
`;
export const _changePassword = gql`
  mutation ChangePasswordByPvs($input: changePasswordByPvsIn) {
    changePasswordByPvs(input: $input) {
      status
    }
  }
`;

export const _paymentHistory = gql`
  query SearchPeymentRecipts($input: PaymentReciptSearchIn) {
    searchPeymentRecipts(input: $input) {
      limit
      skip
      resultCount
      result {
        _id
        final_amount
        created_at
        payment_recipt_status
        subject
      }
    }
  }
`;
export const _paymentReceipt = gql`
  query GetPeymentRecipt($itemId: String) {
    getPeymentRecipt(itemId: $itemId) {
      recipt {
        _id
        amount
        created_at
        discount
        final_amount
        payment_recipt_status
        student_name
      }
      payment_items {
        payment_recipt_id
        amount
        cources_id
        created_at
        year
        month
        subject
      }
    }
  }
`;
export const _searchCourier = gql`
  query SearchCourierRecords($filter: CourierIn) {
    searchCourierRecords(input: $filter) {
      limit
      skip
      resultCount
      result{
        _id
        nic
        tracking_no
        created_at
      }
    }
  }
`;